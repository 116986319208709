var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.classes)?_c('div',{class:[
    'class',
    {
      'type-feature': _vm.type === 'feature',
    } ]},[_c('img',{staticClass:"img-title",attrs:{"src":_vm.imgTitleSrc,"alt":"classes title"}}),_vm._v(" "),_c('video',{key:("classes_video_" + _vm.characterId),ref:"video",staticClass:"video",attrs:{"autoplay":"","loop":"","muted":"","preload":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":(_vm.CDN + "/class/classes_video_" + _vm.characterId + ".mp4"),"type":"video/mp4"}})]),_vm._v(" "),_c('div',{staticClass:"bg"}),_vm._v(" "),_c('div',{staticClass:"btn-class-section"},_vm._l((_vm.classList),function(rowClass,index){return _c('div',{key:index,staticClass:"row"},_vm._l((rowClass),function(mbmClass){return _c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.isDisable(mbmClass)
            ? {
                content: _vm.$t('STATUS__COMING_SOON'),
              }
            : {}
        ),expression:"\n          isDisable(mbmClass)\n            ? {\n                content: $t('STATUS__COMING_SOON'),\n              }\n            : {}\n        "}],key:mbmClass.id,class:[
          'btn-class',
          {
            active: _vm.characterId === mbmClass.id,
            disable: _vm.isDisable(mbmClass),
          } ],attrs:{"aria-label":"class"},on:{"click":function($event){return _vm.handleBtnClassClick(mbmClass)}}},[_c('p',{staticClass:"name"},[_vm._v(_vm._s(mbmClass.name))])])}),0)}),0),_vm._v(" "),_vm._l((_vm.classes),function(mbmClass){return _c('div',{key:mbmClass.id,class:[
      'character',
      {
        active: _vm.characterId === mbmClass.id,
      } ]},[(!_vm.isDisable(mbmClass))?[_c('transition',{attrs:{"name":"character-fade"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.characterId === mbmClass.id),expression:"characterId === mbmClass.id"}],attrs:{"src":(_vm.CDN + "/class/classes_character_" + (mbmClass.id) + _vm.imgSuffix + ".png"),"alt":"character"}})])]:_vm._e()],2)}),_vm._v(" "),_c('div',{staticClass:"info"},[_c('img',{staticClass:"name",attrs:{"src":(_vm.CDN + "/class/classes_name_" + _vm.characterId + ".png"),"alt":"character name"}}),_vm._v(" "),_c('p',{staticClass:"desc"},[_vm._v(_vm._s(_vm.desc))]),_vm._v(" "),_c('img',{staticClass:"skill-chart",attrs:{"src":(_vm.CDN + "/class/classes_skill_" + _vm.characterId + ".png"),"alt":"character skill chart"}})])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }