//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import VideoBg from './VideoBg';
import constants from '~/constants';

export default {
  name: 'HomeBanner',

  components: {
    VideoBg,
  },

  data() {
    return {
      CDN: process.env.CDN,
      constants,
    };
  },

  computed: {
    ...mapState(['config', 'isMobile', 'isBgvStopAtFirst']),
    isBtnPlayShow() {
      return this.isBgvStopAtFirst;
    },
  },

  methods: {
    ...mapActions(['updateIsBgvStopAtFirst', 'setCurrentModal']),
    btnDownloadClick(platform) {
      this.$gtag('event', `download_${platform}`);
      this.$fb.fbq('trackCustom', `download_${platform}`);
    },
    handleBtnPlayClick() {
      this.$root.$refs.bgv.play();
      this.updateIsBgvStopAtFirst(false);
    },
  },
};
