import { render, staticRenderFns } from "./HomeNews.vue?vue&type=template&id=61c96567&scoped=true&"
import script from "./HomeNews.vue?vue&type=script&lang=js&"
export * from "./HomeNews.vue?vue&type=script&lang=js&"
import style0 from "./HomeNews.vue?vue&type=style&index=0&id=61c96567&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61c96567",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CategoryList: require('/var/www/mbm.garena.all/frontend/components/CategoryList.vue').default})
