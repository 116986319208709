var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-scene"},[_c('img',{staticClass:"img-title",attrs:{"src":(_vm.CDN + "/title_scene.png"),"alt":"scene title"}}),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"section"},[_vm._l((_vm.config.scene.images),function(imageId,index){return _c('div',{key:imageId,class:[
          'wrapper',
          'wrapper-image',
          {
            hover: _vm.target,
            active: _vm.target === ("image_" + imageId),
          } ],on:{"mouseover":function($event){_vm.target = "image_" + imageId},"mouseleave":function($event){_vm.target = null},"click":function($event){_vm.activeImageId = index}}},[_c('img',{staticClass:"image",attrs:{"src":(_vm.CDN + "/scene/scene_image_" + imageId + ".jpg"),"alt":"scene image"}})])}),_vm._v(" "),_c('div',{class:[
          'wrapper',
          'link-image',
          {
            hover: _vm.target,
            active: _vm.target === 'link-image',
          } ],on:{"mouseover":function($event){_vm.target = 'link-image'},"mouseleave":function($event){_vm.target = null}}},[_c('nuxt-link',{staticClass:"link",attrs:{"to":"/scene","aria-label":"scene link"}})],1)],2),_vm._v(" "),_c('div',{staticClass:"section"},[_vm._l((_vm.config.scene.videos),function(video,index){return _c('div',{key:video.id,class:[
          'wrapper',
          'wrapper-video',
          {
            hover: _vm.target,
            active: _vm.target === ("video_" + (video.id)),
          } ],on:{"mouseover":function($event){_vm.target = "video_" + (video.id)},"mouseleave":function($event){_vm.target = null},"click":function($event){_vm.activeVideoId = index}}},[_c('img',{staticClass:"video",attrs:{"src":(_vm.CDN + "/scene/scene_video_" + (video.id) + ".jpg"),"alt":"scene video"}}),_vm._v(" "),_c('div',{staticClass:"icon-play"})])}),_vm._v(" "),_c('div',{class:[
          'wrapper',
          'link-video',
          {
            hover: _vm.target,
            active: _vm.target === 'link-video',
          } ],on:{"mouseover":function($event){_vm.target = 'link-video'},"mouseleave":function($event){_vm.target = null}}},[_c('nuxt-link',{staticClass:"link",attrs:{"to":"/scene?active=1","aria-label":"scene link"}})],1)],2)]),_vm._v(" "),_c('client-only',[_c('CoolLightBox',{attrs:{"items":_vm.imageItems,"index":_vm.activeImageId},on:{"close":function($event){_vm.activeImageId = null}}}),_vm._v(" "),_c('CoolLightBox',{attrs:{"items":_vm.videoItems,"index":_vm.activeVideoId},on:{"close":function($event){_vm.activeVideoId = null}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }