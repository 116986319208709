//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import CategoryList from '../../CategoryList';

export default {
  name: 'HomeNews',

  components: {
    CategoryList,
  },

  data() {
    return {
      CDN: process.env.CDN,
      activeCategory: 0,
      initialSlide: 0, // for CategoryList initialSlide
    };
  },

  computed: {
    ...mapState(['config']),
    categories() {
      if (!this.config.newsCategories) return [];
      const categories = [
        {
          id: 0,
          name: this.$i18n.t('NEWS__CATEGORY__0'),
        },
        ...this.config.newsCategories.slice(0, 3),
      ];
      return categories;
    },
  },

  methods: {
    handleCategoryClick(categoryId, initialSlide) {
      this.activeCategory = categoryId;
      this.initialSlide = initialSlide;
    },
    activeHomeNewsList(activeCategory) {
      return (
        this.config.news.find((news) => news.categoryId === activeCategory)
          ?.news || []
      );
    },
    categoryName(categoryId) {
      return (
        this.config.newsCategories.find(
          (category) => category.id === categoryId,
        )?.name || ''
      );
    },
  },
};
