//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from 'vuex';
import constants from '~/constants';

export default {
  name: 'Class',

  props: {
    type: {
      type: String,
      default: 'home',
    },
  },

  data() {
    return {
      CDN: process.env.CDN,
      constants,
      characterId: 1,
    };
  },

  computed: {
    ...mapState(['classes', 'isMobile']),
    ...mapGetters(['imgSuffix']),
    desc() {
      return this.classes.filter((el) => el.id === this.characterId)[0].desc;
    },
    imgTitleSrc() {
      return `${this.CDN}/${
        this.type === 'feature'
          ? 'feature/feature_title_2'
          : 'class/title_classes'
      }.png`;
    },
    classList() {
      let classList = [];
      let isOdd = true;
      let count = 5;
      let tempClasses = [];
      this.classes.forEach((mbmClass, index) => {
        tempClasses = [...tempClasses, mbmClass];
        count -= 1;
        if (count === 0 || index === this.classes.length - 1) {
          classList = [...classList, tempClasses];
          tempClasses = [];
          isOdd = !isOdd;
          count = isOdd ? 5 : 4;
        }
      });
      return classList;
    },
  },

  mounted() {
    this.characterId = this.classes[0].id;
  },

  methods: {
    handleBtnClassClick(mbmClass) {
      if (this.isDisable(mbmClass)) return;

      this.characterId = mbmClass.id;
    },
    isDisable(mbmClass) {
      return mbmClass.status === constants.STATUS__COMING_SOON;
    },
  },
};
