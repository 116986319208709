var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'home-banner',
    {
      mobile: _vm.$device.isMobile,
    } ]},[_c('VideoBg'),_vm._v(" "),_c('div',{staticClass:"content"},[_c('button',{class:[
        'btn-play',
        {
          show: _vm.isBtnPlayShow,
        } ],attrs:{"aria-label":"play"},on:{"click":_vm.handleBtnPlayClick}}),_vm._v(" "),_c('div',{staticClass:"title-section"},[_c('img',{staticClass:"sub-title",attrs:{"src":(_vm.CDN + "/sub.svg"),"alt":"subtitle"}}),_vm._v(" "),_c('img',{staticClass:"tagline",attrs:{"src":(_vm.CDN + "/logo.png"),"alt":"tagline"}})]),_vm._v(" "),_c('div',{staticClass:"download-section"},[_c('div',{staticClass:"qrcode-wrapper"},[_c('img',{staticClass:"qrcode",attrs:{"src":(_vm.CDN + "/qr_code.png"),"alt":"qrcode"}})]),_vm._v(" "),_c('div',{staticClass:"mobile"},[_c('div',{staticClass:"android",on:{"click":function($event){_vm.config.gameDownloadLinks.googlePlay && _vm.btnDownloadClick('android')}}},[_c('a',{attrs:{"href":_vm.config.gameDownloadLinks.googlePlay,"target":"_blank","aria-label":"android download link"}})]),_vm._v(" "),_c('div',{staticClass:"ios",on:{"click":function($event){_vm.config.gameDownloadLinks.appStore && _vm.btnDownloadClick('ios')}}},[_c('a',{attrs:{"href":_vm.config.gameDownloadLinks.appStore,"target":"_blank","aria-label":"ios download link"}})])]),_vm._v(" "),_c('div',{class:[
          'pc',
          {
            disable: !_vm.config.gameDownloadLinks.pcSimulator,
          } ],on:{"click":function($event){_vm.config.gameDownloadLinks.pcSimulator && _vm.btnDownloadClick('pc')}}},[_c('a',{attrs:{"href":_vm.config.gameDownloadLinks.pcSimulator,"target":"_blank","aria-label":"pc simulator download link"}})])])]),_vm._v(" "),(_vm.config.indexVideoYoutubeId)?_c('button',{staticClass:"btn-trailer",attrs:{"aria-label":"trailer"},on:{"click":function($event){return _vm.setCurrentModal(_vm.constants.MODAL__TRAILER)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }