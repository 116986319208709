import { render, staticRenderFns } from "./index.vue?vue&type=template&id=12a2e190&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=12a2e190&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12a2e190",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeBanner: require('/var/www/mbm.garena.all/frontend/components/home/HomeBanner/index.vue').default,SideBar: require('/var/www/mbm.garena.all/frontend/components/home/SideBar.vue').default,HomeBoard: require('/var/www/mbm.garena.all/frontend/components/home/HomeBoard/index.vue').default,Class: require('/var/www/mbm.garena.all/frontend/components/Class.vue').default,HomeGuide: require('/var/www/mbm.garena.all/frontend/components/home/HomeGuide.vue').default,HomeStory: require('/var/www/mbm.garena.all/frontend/components/home/HomeStory.vue').default,HomeScene: require('/var/www/mbm.garena.all/frontend/components/home/HomeScene.vue').default})
