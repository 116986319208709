//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'HomeScene',

  data() {
    return {
      CDN: process.env.CDN,
      target: null,
      activeImageId: null,
      activeVideoId: null,
    };
  },

  computed: {
    ...mapState(['config']),
    imageItems() {
      // real size image
      return this.config.scene.images.map(
        (imageId) => `${this.CDN}/scene/scene_image_${imageId}_big.jpg`,
      );
    },
    videoItems() {
      return this.config.scene.videos.map((video) => ({
        thumb: `${this.CDN}/scene/scene_image_${video.id}.jpg`,
        src: `https://www.youtube.com/embed/${video.youtubeId}`,
      }));
    },
  },
};
