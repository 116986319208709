//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  name: 'SideBar',

  data() {
    return {
      CDN: process.env.CDN,
      REGION: process.env.REGION,
      isFixed: false,
      isOpen: true,
      communities: {
        tw: ['fb', 'ig', 'yt'],
        th: ['fb', 'yt', 'discord', 'line'],
      },
    };
  },

  computed: {
    ...mapState(['config']),
    regionCommunities() {
      return this.communities[process.env.REGION];
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.handleScroll();
    });
    window.addEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll() {
      const fontSize = (window.innerWidth / 1920) * 100;
      const topOffset = process.env.REGION === 'th' ? 0 : 43;
      const topBannerHeight = window.innerHeight - fontSize * 1.02 - topOffset;
      const topBannerMinHeight = fontSize * 6.6;
      let topBannerOffset = 0;

      if (topBannerHeight < topBannerMinHeight) {
        // height < min-height
        topBannerOffset = topBannerMinHeight - topBannerHeight;
      }

      this.isFixed =
        window.pageYOffset >
        0.5 * window.innerHeight + topBannerOffset + fontSize * (1.1 + 1.665);
      // ( HomeBoard's margin-top + topBannerOffset + side-bar half height)
    },
    communityLink(type) {
      const communityLink = this.config.communities.filter((community) => {
        return new RegExp(type).test(community.imageUrl);
      });

      return communityLink.length > 0 ? communityLink[0].link : '/';
    },
    handleCommunityClick(community) {
      this.$gtag('event', `sidebar_${community}`);
    },
  },
};
