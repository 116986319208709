//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import HomeBanner from '~/components/home/HomeBanner';
import HomeBoard from '~/components/home/HomeBoard';
import Class from '~/components/Class';
import HomeGuide from '~/components/home/HomeGuide';
import HomeStory from '~/components/home/HomeStory';
import HomeScene from '~/components/home/HomeScene';
import SideBar from '~/components/home/SideBar';

export default {
  name: 'Home',

  components: {
    HomeBanner,
    HomeBoard,
    Class,
    HomeGuide,
    HomeStory,
    HomeScene,
    SideBar,
  },

  data() {
    return {
      CDN: process.env.CDN,
      REGION: process.env.REGION,
    };
  },

  computed: {
    ...mapState(['config']),
  },

  head: {
    script: [
      {
        src: `https://connect.facebook.net/${process.env.LOCALIZATION}/sdk.js#xfbml=1&version=v14.0`,
        async: true,
      },
    ],
  },

  async validate({ store }) {
    const [classes, guides] = await Promise.all([
      store.dispatch('getClasses'),
      store.dispatch('getGuides', {}),
    ]);

    // config is fetched in middleware
    return !!store.state.config && !!classes && !!guides;
  },
};
