//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions, mapGetters } from 'vuex';
import constants from '~/constants';
import CategoryList from '~/components/CategoryList';
import BannerSwiper from '~/components/BannerSwiper';
import Loading from '~/components/Loading';

export default {
  name: 'HomeGuide',

  components: {
    CategoryList,
    BannerSwiper,
    Loading,
  },

  data() {
    return {
      CDN: process.env.CDN,
      activeCategory: 0,
      initialSlide: 0, // for CategoryList initialSlide
    };
  },

  computed: {
    ...mapState(['guides', 'isLoading']),
    ...mapGetters(['guideCategoryName']),
    categories() {
      if (!this.guides) return [];
      const categories = [
        {
          id: 0,
          name: this.$i18n.t('GUIDE__CATEGORY__0'),
        },
        ...this.guides.categories,
      ];
      return categories;
    },
  },

  methods: {
    ...mapActions(['getGuides']),
    async handleCategoryClick(categoryId, initialSlide) {
      const guides = await this.getGuides({
        category: categoryId,
        withTop: constants.WITHOUT_TOP,
      });

      if (guides) {
        this.activeCategory = categoryId;
        this.initialSlide = initialSlide;
      }
    },
  },
};
