//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

export default {
  name: 'VideoBg',

  data() {
    return {
      CDN: process.env.CDN,
      imgSrc: '',
      videoSrc: '',
    };
  },

  computed: {
    ...mapState(['config', 'isMobile', 'isBgvStopAtFirst']),
    isVideoShow() {
      return this.videoSrc && !this.isBgvStopAtFirst;
    },
  },

  watch: {
    isMobile: {
      handler(val) {
        this.imgSrc = val
          ? this.config.kv.mobile.imageUrl
          : this.config.kv.pc.imageUrl;
        this.videoSrc = val
          ? this.config.kv.mobile.videoUrl
          : this.config.kv.pc.videoUrl;

        if (val && process.env.REGION === 'th' && !this.isBgvStopAtFirst) {
          this.$refs.bgv.removeAttribute('autoplay', 'autoplay');
          this.updateIsBgvStopAtFirst(true);
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.$root.$refs.bgv = this.$refs.bgv;
  },

  methods: {
    ...mapActions(['updateIsBgvStopAtFirst']),
  },
};
