//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import HomeNews from './HomeNews';

export default {
  name: 'HomeBoard',

  components: {
    HomeNews,
  },

  data() {
    return {
      CDN: process.env.CDN,

      // settings for swiper
      swiperOption: {
        spaceBetween: 0,
        speed: 400,
        autoplay: {
          delay: 3000,
        },
        lazy: {
          loadPrevNext: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletClass: 'bullet',
          bulletActiveClass: 'bullet-active',
        },
      },
    };
  },

  computed: {
    ...mapState(['config']),
    fbHref() {
      const fbHref = this.config.communities.filter((community) => {
        return new RegExp('fb').test(community.imageUrl);
      });

      return fbHref.length > 0 ? fbHref[0].link : '/';
    },
  },

  mounted() {
    setTimeout(() => {
      this.resetFbIframe();
    }, 1500);
    window.addEventListener('resize', this.resetFbIframe);
  },

  methods: {
    resetFbIframe() {
      if (window.FB) {
        window.FB.XFBML.parse();
      }
    },
    handleCommunityClick(community) {
      const communityName = community.imageUrl.match(
        new RegExp('icon_(.*).jpg'),
      );
      if (communityName) {
        this.$gtag('event', `mainpage_${communityName[1]}`);
      }
    },
  },
};
